@import "../../../variables.scss";

.new-session {
  position: fixed;
  right: 2rem;
  bottom: 2rem;

  .button {
    font-size: 1.5rem;
  }
}

@media screen and (min-width: $mobile-min-width) {

  .new-session {
    .button {
      font-size: 2.5rem;
    }
  }
}