@import "../../../variables.scss";

.toggle-cards {
  padding: 0;
  margin-top: 0.75rem;
  margin-bottom: 0.5rem;
}

.estimation-component {
  padding: 1.125rem;
  padding-top: 2.25rem;
  width: 100%;
}

.estimation-actions-component {
  text-align: center;
}

.estimation-metadata-component {
  &__head {
    text-align: center;
    font-size: 1.75rem;
    margin-bottom: 1rem;
  }

  &__story-title-value,
  &__story-description-value,
  &__story-vote-value {
    display: block;
    margin-top: 0.5rem;
    word-break: break-all;
    a {
      font-size: inherit;
      background-color: inherit;
      color: inherit;
      border-bottom: 0.0625rem solid;

      &:hover {
        color: var(--purple);
      }
    }
  }

  &__story-description,
  &__story-vote {
    margin-top: 0.625rem;
  }

  &__story-data {
    min-height: 3.25rem;
  }
}

.estimation-cards {
  position: relative;
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--background-color);
    opacity: 70%;
    cursor: not-allowed;
  }

  &__overlay-warning {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  &__card {
    margin: 0.625rem;
    width: 85%;
    border: 0.1875rem solid currentColor;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotateY(360deg);
    transition: transform 1s;
    font-size: 3rem;

    &:active {
      transform: rotateY(0);
      transition: 0s;
    }

    &:hover,
    &--active {
      cursor: pointer;
      color: var(--background-color);
      &.green-text {
        background-color: var(--green);
        border-color: var(--green);
      }
      &.yellow-text {
        background-color: var(--yellow);
        border-color: var(--yellow);
      }
      &.blue-text {
        background-color: var(--blue);
        border-color: var(--blue);
      }
      &.orange-text {
        background-color: var(--orange);
        border-color: var(--orange);
      }
      &.purple-text {
        background-color: var(--purple);
        border-color: var(--purple);
      }
      &.fuschia-text {
        background-color: var(--fuschia);
        border-color: var(--fuschia);
      }
    }

    &.low-font-size {
      font-size: 1.75rem;
    }
  }

  &__card-value {
    color: inherit;
    font-size: inherit;
    width: 100%;
    text-align: center;
    justify-content: center;
    display: inline-flex;
    align-items: center;
    height: 100%;
    padding-top: 30%;
    padding-bottom: 30%;
    border: 0.3125rem solid var(--background-color);
  }
}

@media screen and (min-width: $mobile-min-width) {
  .toggle-cards {
    display: none;
  }
  .estimation-component {
    padding-top: 1.125rem;
  }

  .estimation-cards {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    &__card {
      font-size: 7rem;
      &.low-font-size {
        font-size: 3.375rem;
      }
    }
  }

  .estimation-metadata-component {
    &__head {
      font-size: 2rem;
      margin-bottom: 2rem;
    }
    &__story-title-value {
      margin-left: 6rem;
      margin-top: 0;
      display: unset;
    }
    &__story-description-value {
      margin-left: 0.8rem;
      margin-top: 0;
      display: unset;
    }
    &__story-vote-value {
      margin-left: 1.6rem;
      margin-top: 0;
      display: unset;
    }
  }
}
