@import "../../../variables.scss";

.sessions-container {
  padding: 1rem 0;
  width: 100%;

  .text {
    text-align: center;
  }
}

.session {
  display: flex;
  flex-direction: column;
  padding: 1rem;

  &__meta {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  &__title {
    font-size: 1.4rem;
  }

  &__security {
    display: flex;
    flex-direction: row;
  }

  &__info,
  &__rotate,
  &__lock {
    cursor: pointer;
    svg {
      height: 2rem;
    }
    
    svg g {
      fill: var(--green);
    }

    &--unsafe svg g {
      fill: var(--red);
    }
  }

  &__info {
    svg {
      height: 2.2rem;
      g {
        fill: var(--green);
      }
    }
  }

  &__actions {

    &--active {
      display: flex;
    }

    padding: 1rem 0;
    width: 100%;
    display: none;
    flex-wrap: wrap;
    justify-content: space-around;

    .button {
      text-align: left;
      font-size: 1.5rem;
      margin: 1rem auto;

      &:nth-of-type(2n) {
        text-align: right;
      }
    }
  }
}

@media screen and (min-width: $tablet-min-width) {

  .sessions-container {
    padding: 1rem 0;
  }

  .session {
    flex-direction: row;

    &__meta {
      flex-basis: 100%;
      padding-right: 2rem;
    }

    &__security {
      padding-left: 1rem;
    }

    &__actions {
      display: flex;
      padding: 0;
      justify-content: flex-end;
      
      .button {
        flex-basis: auto;
        width: auto;
        margin: 0 1%;
        align-self: baseline;
      }
    }
  }
}