@import "../../../variables.scss";

.organisation-container {
  padding: 1rem 0;
}
.organisation {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 100%;
  &__meta {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
  }
  &__title {
    display: flex;
    flex-direction: column;
    font-size: 1.5rem;
  }
  &__actions {
    &--active {
      display: block;
    }
    padding: 1rem 0;
    width: 100%;
    display: none;
    flex-wrap: wrap;
    justify-content: space-around;

    .button {
      flex-basis: 50%;
      text-align: left;
      font-size: 1.5rem;
      margin: 1rem auto;

      &:nth-of-type(2n) {
        text-align: right;
      }
    }
  }
}

@media screen and (min-width: $tablet-min-width) {
  .organisation-container {
    padding: 1rem 0;
    width: 100%;
  }
  .organisation {
    flex-direction: row;

    &__meta {
      flex-basis: 100%;
      justify-content: flex-start;
    }

    &__actions {
      display: flex;
      padding: 0;
      justify-content: flex-end;

      .button {
        flex-basis: auto;
        width: auto;
        margin: 0 0.4rem;
        align-self: baseline;
      }
    }
  }
}
