@import "../../../variables.scss";

.users-component {
  padding: 1.125rem;
  width: 100%;

  &__head {
    text-align: center;
    font-size: 1.75rem;
    margin-bottom: 1rem;
  }

  &__user {
    font-size: 1.25rem;
    margin-bottom: 0.75rem;
    &--pending {
      color: var(--yellow);
    }
    &--voted {
      color: var(--green);
    }
  }

  &__user-vote {
    margin-left: 1rem;
  }

  &__user-vote,
  &__user-name {
    color: inherit;
    font-size: inherit;
  }

  &__vote-element-estimation {
    color: inherit;
  }

  &__vote-element-users {
    padding-left: 1.5rem;
    margin-top: 0.75rem;
    color: inherit;
  }

  &__vote-element-user {
    margin-bottom: 0.75rem;
    color: inherit;
  }
}

@media screen and (min-width: $mobile-min-width) {
  .users-component {
    &__head {
      font-size: 2rem;
      margin-bottom: 2rem;
    }

    display: unset;
  }
}
