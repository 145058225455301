@import "../../../variables.scss";

.header {
  padding-top: 0.5rem;
  width: 100%;
  padding-bottom: 0.5rem;
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: start;
  align-content: flex-start;

  &__column {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: flex-start;
    &--left {
      width: 33%;
      justify-content: start;
    }
    &--center {
      width: 33%;
    }
    &--right {
      justify-content: flex-end;
      width: 33%;
    }
  }

  &__logo-title {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: flex-start;
    cursor: pointer;
  }

  &__image {
    background-image: url("./l1.webp");
    background-repeat: no-repeat;
    background-size: 1.875rem 2.25rem;
    max-height: 2.25rem;
    height: 2.25rem;
    width: 2.25rem;

    &:hover {
      background-image: url("./l2.webp");
    }
  }

  &__text {
    align-items: center;
    display: flex;
    margin-left: 1rem;
  }

  &__settings {
    height: 2.5rem;
    width: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: flex-start;
    padding-right: 1rem;

    &-title {
      margin-right: 1rem;
      display: none;
    }
  }
}

.gear {
  width: 2rem;
  height: 1.5rem;
  background-color: var(--text-color);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &.opened {
    width: 0;
    height: 0;
  }

  &:hover {
    background-color: var(--yellow);
    cursor: pointer;
  }

  .line {
    width: 0.625rem;
    height: 1.75rem;
    background-color: inherit;
    border-radius: 1px;
    position: absolute;
    transition: 0.4s;

    transform: rotate(30deg);
    -webkit-transform: rotate(30deg);

    &:nth-child(2) {
      transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
    }
    &:nth-child(3) {
      transform: rotate(150deg);
      -webkit-transform: rotate(150deg);
    }
  }

  .circle {
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    background: var(--background-color);
    z-index: 1;
  }
}

.opened .line:nth-child(1) {
  -webkit-transform: rotate(-45deg) translate(0.4375rem, 0.375rem);
  transform: rotate(-45deg) translate(0.4375rem, 0.375rem);
  width: 0.3125rem;
  height: 2.1875rem;
  margin-right: 1rem;
}

.opened .line:nth-child(2) {
  opacity: 0;
}

.opened .circle {
  opacity: 0;
}

.opened .line:nth-child(3) {
  -webkit-transform: rotate(45deg) translate(0.35rem, -0.4375rem);
  transform: rotate(45deg) translate(0.35rem, -0.4375rem);
  width: 0.3125rem;
  height: 2.1875rem;
  margin-right: 1rem;
}

@media screen and (min-width: $mobile-min-width) {
  .header {
    &__settings {
      display: none;
      &-title {
        display: inline-block;
      }
    }
  }
}

.header-left-column {
  &__content {
    position: fixed;
    background: var(--background-color);
    top: 0;
    left: -100%;
    height: 100%;
    z-index: 101;
    width: 100%;
    overflow-x: hidden;
    &.opened {
      left: 0;
      animation: 1s normal moveStoriesSidebar;
    }
    &.opened::-webkit-scrollbar  {
      display: none;
    }
    &.opened {
      scrollbar-width: none;
    }
    &.closed {
      left: -100%;
      animation: 1s normal moveStoriesSidebarBack;
    }
  }

  &__menu {
    position: fixed;
    top: 0;
    z-index: 102;
    left: 0;
    padding-top: 0.25rem;
    padding-left: 0.5rem;
    &.opened {
      left: 88%;
      animation: 1s normal moveMenuIcon;
    }
    &.closed {
      left: 0;
      animation: 1s normal moveMenuIconBack;
    }
  }

  &__stories {
    display: block;
    .story-list {
      display: block;
    }
  }

  &__links {
    padding-top: 1rem;
    padding-left: 1rem;
  }
}

@media screen and (min-width: $mobile-min-width) {
  .header-left-column {
    display: unset;
    padding-left: 1rem;

    &__links {
      padding-top: 0rem;
      padding-left: 0rem;
    }

    &__head {
      font-size: 2rem;
    }

    &__content {
      position: unset;
    }

    &__menu {
      display: none;
      z-index: 101;
    }

    &__stories {
      display: none;
      .story-list {
        display: none;
      }
    }
  }
}

@keyframes moveStoriesSidebarBack {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
@-webkit-keyframes moveStoriesSidebarBack {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}

@keyframes moveMenuIcon {
  0% {
    left: 0;
  }
  100% {
    left: 88%;
  }
}
@-webkit-keyframes moveMenuIcon {
  0% {
    left: 0;
  }
  100% {
    left: 88%;
  }
}

@keyframes moveMenuIconBack {
  0% {
    left: 88%;
  }
  100% {
    left: 0;
  }
}
@-webkit-keyframes moveMenuIconBack {
  0% {
    left: 88%;
  }
  100% {
    left: 0;
  }
}

@keyframes moveStoriesSidebar {
  0% {
    left: -100%;
  }
  100% {
    left: 0;
  }
}
@-webkit-keyframes moveStoriesSidebar {
  0% {
    left: -100%;
  }
  100% {
    left: 0;
  }
}
