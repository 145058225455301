@import "../../../variables.scss";

.new-backup {
  position: fixed;
  right: 2rem;
  bottom: 2rem;

  .button {
    font-size: 1.5rem;
  }
}

.rmodal {
  &__backup-title {
    color: var(--purple);
    text-align: center;
    padding-bottom: 2rem;
    font-size: 2rem;
  }
}

@media screen and (min-width: $mobile-min-width) {
  .new-backup {
    .button {
      font-size: 2.5rem;
    }
  }

  .rmodal {
    &__backup-title {
      font-size: 3.6rem;
    }
  }
}
