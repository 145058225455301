@import "../../../variables.scss";

.rmodal {
  &__disconected-title {
    font-size: 3rem;
    color: var(--purple);
    text-align: center;
    padding-bottom: 4rem;
  }

  &-actions-disconnect {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    .button {
      font-size: 1.1rem;
    }
  }
}

@media screen and (min-width: $mobile-min-width) {
  .rmodal {
    &__disconected-title {
      font-size: 3.6rem;
    }

    &-actions-disconnect {
      justify-content: center;

      .button {
        font-size: 2rem;
        margin: 0 2rem;
      }
    }
  }
}
