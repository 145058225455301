@import "../../../variables.scss";

.new-organisation {
  position: fixed;
  right: 2rem;
  bottom: 2rem;

  .button {
    font-size: 1.5rem;
  }
}

@media screen and (min-width: $mobile-min-width) {
  .new-organisation {
    .button {
      font-size: 2.5rem;
    }
  }
}
