@import "../../../variables.scss";

@media screen and (min-width: $mobile-min-width) {
  .session-page-main-content {
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    align-items: start;
    justify-content: start;
    align-content: flex-start;
    border-top: 0.0625rem solid var(--blue);

    &__left-column {
      width: 25%;
      display: flex;
      align-items: start;
      justify-content: start;
      align-content: flex-start;
    }

    &__center-column {
      width: 50%;
      display: flex;
      align-items: start;
      justify-content: start;
      align-content: flex-start;
      height: calc(100vh - 58px);
      border-left: 0.0625rem solid var(--blue);
      border-right: 0.0625rem solid var(--blue);
    }

    &__right-column {
      width: 25%;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: start;
      align-content: flex-start;

      .button {
        align-self: center;
      }
    }
  }
}
