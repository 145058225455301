@import "../../../variables.scss";

.organisation-page {
  display: flex;
  flex-direction: column;
}

.administrator {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;

  &__meta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin: 0 auto 1.3rem auto;
    align-items: center;

    .button {
      font-size: 1.4rem;
    }
  }

  &__title {
    text-align: center;
    font-size: 3rem;
    padding: 0.5rem 0 1rem 0;
  }

  &__admins-title {
    display: flex;
    flex-direction: column;
    font-size: 1.6rem;
    padding-top: 0.5rem;
  }

  &__organisation-admins,
  &__session-admins,
  &__story-mapping {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 0.7rem 0;
    padding-left: 2.5rem;
  }

  &__prefix-url {
    &--active {
      display: flex;
    }

    padding: 0.5rem 0;
    width: 100%;
    display: none;
    flex-wrap: wrap;
    word-break: break-all;

  }
  &__actions {
    &--active {
      display: flex;
      justify-content: flex-end;
    }

    padding: 0.5rem 0;
    width: 100%;
    display: none;
    flex-wrap: wrap;

    .button {
      font-size: 1.4rem;

      &:nth-of-type(2n) {
        text-align: right;
      }
    }
  }

  &__email,
  &__story-naming {
    display: flex;
    flex-direction: row;
    font-size: 1.4rem;
    flex-wrap: wrap;
  }
}

@media screen and (min-width: $tablet-min-width) {
  .organisation-page {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .administrator-container {
    padding: 1rem;
    width: 100%;
  }

  .administrator {
    padding: 1rem;

    &__admins,
    &__mapping {
      display: flex;
      flex-direction: row;
    }

    &__meta {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      margin: 0 auto 1.3rem auto;
      align-items: baseline;
      
      .button {
        margin-left: 0.5rem;
        font-size: 1.5rem;
      }
    }

    &__admins-title {
      font-size: 2rem;
    }

    &__email,
    &__story-naming,
    &__prefix-url {
      font-size: 1.5rem;
      align-content: center;
    }

    &__prefix-url {
      display: flex;
      word-break: unset;
    }

    &__actions {
      display: flex;
      padding: 0;
      justify-content: flex-end;
      align-content: center;

      .button {
        flex-basis: auto;
        width: auto;
        align-self: baseline;
        font-size: 1.5rem;
        margin-left: 1%;
      }
    }
  }
}
