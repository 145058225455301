@import "../../../variables.scss";

.ReactModal__Overlay {
  background-color: transparent !important;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}

@supports not (backdrop-filter: none) {
  .ReactModal__Overlay {
    background-color: rgba(0, 0, 0, 0.75) !important;
  }
}

.rmodal {
  &__title {
    font-size: 2rem;
    color: var(--purple);
    text-align: center;
  }

  .input-wrapper {
    display: flex;
    flex-direction: row;
    margin: 2rem auto;

    .input {
      text-align: center;
      color: var(--yellow);

      .number {
        border-bottom: 2px solid var(--purple);
      }
    } 
  }

  &-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    .button {
      font-size: 1.5rem;
      align-self: baseline;
    }
  }
}

@media screen and (min-width: $mobile-min-width) {
  .rmodal {
    &__title {
      font-size: 3.6rem;
    }

    .input-wrapper {
      justify-content: center;

      .input-carrets {
        font-size: 2.5rem;
      }

      .input {
        font-size: 2.5rem;
      }
    }

    &-actions {
      justify-content: center;

      .button {
        font-size: 2.5rem;
        margin: 0 2rem;
      }
    }
  }
}
