.toggle {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin: 0 1rem;

  .input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .toggle__slider {
      background-color: var(--background-color);
    }

    &:focus + .toggle__slider {
      box-shadow: 0 0 1px var(--text-color);
    }

    &:checked + .toggle__slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
  }

  &__slider {
    box-shadow: 0 0 1px var(--text-color);
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(var(--blue-rgb), 0.4);
    -webkit-transition: 0.4s;
    transition: 0.4s;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.35rem;
  
    &-info {
      font-size: 0.75rem;
    }
  
    &:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: var(--purple);
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }
}

