@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./font.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

:root {
  --background-color: #1a1a1a;
  --text-color: #e1e1e1;

  --green: #1beb9e;
  --yellow: #dddd25;
  --purple: #b462ff;
  --orange: #ff9528;
  --blue: #18b6ff;
  --fuschia: #ff45b4;
  --red: #a40000;

  --notification-error: rgba(164, 0, 0, 0.85);
  --notification-warning: rgba(159, 103, 0, 0.85);
  --notification-success: rgba(0, 97, 66, 0.85);

  --green-rgb: 27, 235, 158;
  --blue-rgb: 24, 182, 255;
  --yellow-rgb: 221, 221, 37;
  --purple-rgb: 180, 98, 255;
}

:root.light-theme {
  --background-color: #e1e1e1;
  --text-color: #1a1a1a;

  --green: darkgreen;
  --yellow: rgb(113, 81, 0);
  --purple: darkviolet;
  --orange: darkcyan;
  --blue: darkblue;
  --fuschia: rgb(208, 0, 111);
}

*[hidden] {
  display: none !important;
}

html {
  font-size: 16px;
}

* {
  box-sizing: border-box;
  font-family: "Roboto Mono", monospace;
  color: var(--text-color);
  font-size: 1.25rem;
  margin: 0;
}

a {
  text-decoration: none;
}

input {
  background-color: transparent;
  outline: none;
  border: none;
}

button,
.button {
  border: none;
  position: relative;
  background-color: transparent;
  cursor: pointer;
  transition: 0.25s;
  text-decoration: none;
  display: inline-block;

  &:hover {
    color: var(--background-color);
    background-color: var(--text-color);
  }

  &--disabled {
    text-decoration: line-through;
    &:hover {
      color: var(--text-color);
      background-color: transparent;
      cursor: not-allowed;
      text-decoration: line-through;
    }
  }

  &::before {
    left: 0;
    position: relative;
    content: "[";
  }

  &::after {
    right: 0;
    position: relative;
    content: "]";
  }
}

.button {
  white-space: nowrap;
  
  &--orange {
    color: var(--orange);

    &:hover {
      background-color: var(--orange);
    }
  }

  &--green {
    color: var(--green);

    &:hover {
      background-color: var(--green);
    }
  }

  &--yellow {
    color: var(--yellow);

    &:hover {
      background-color: var(--yellow);
    }
  }

  &--purple {
    color: var(--purple);

    &:hover {
      background-color: var(--purple);
    }
  }

  &--blue {
    color: var(--blue);

    &:hover {
      background-color: var(--blue);
    }
  }

  &--fuschia {
    color: var(--fuschia);

    &:hover {
      background-color: var(--fuschia);
    }
  }
}

body {
  background-color: var(--background-color);
}

.green-text {
  color: var(--green);
}
.yellow-text {
  color: var(--yellow);
}
.purple-text {
  color: var(--purple);
}
.orange-text {
  color: var(--orange);
}
.blue-text {
  color: var(--blue);
}
.fuschia-text {
  color: var(--fuschia);
}
