@import "../../../variables.scss";

.theme-toggle {
  border-top: 0.15rem solid var(--text-color);
  margin: 1rem auto .5rem;
  padding: 2rem 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  &__label {
    font-size: 1.25rem;
  }
}

@media screen and (min-width: $mobile-min-width) { 
  .theme-toggle {
    border-top: 0.25rem solid var(--text-color);
    margin: 1rem auto;
    padding: 2rem 0;
    &__label {
      font-size: 2.5rem;
    }
  }
}