@import "../../../variables.scss";

.story-list {
  width: 100%;
  display: none;

  &__navigation-text {
    cursor: pointer;
  }

  &__story-view-mode {
    margin-top: 2rem;

    &.hightlited1 {
      animation: 1s normal highlithModeSelector;
    }
    &.hightlited2 {
      animation: 1s normal highlithModeSelector2;
    }
  }

  &__head {
    font-size: 1.75rem;
    display: flex;
    justify-content: space-between;
    margin: 1.9rem 0 0.75rem 3.1rem;
  }

  &__sorting-button {
    margin-top: 0.8rem;
    margin-right: 1.9rem;
    font-size: 1.1rem;
    cursor: pointer;
    position: relative;
  }

  &__sorting-button::before {
    left: 0;
    position: relative;
    content: "↑";
  }

  &__sorting-button::after {
    left: 0;
    position: relative;
    content: "↓";
  }

  &__nav {
    text-align: center;
    margin-bottom: 0.8rem;
  }

  .add-story {
    text-align: center;
    margin: 1rem auto;
    .button {
      font-size: 1.5rem;
    }
  }
}

.story-section {
  border-top: 0.0625rem solid var(--green);
  color: var(--text-color);
  padding: 1rem 0.5rem 1rem 5%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 5.25rem;
  display: flex;
  align-items: center;

  .story-info {
    align-self: flex-start;
  }

  &.non-admin {
    &:hover {
      background-color: rgba(var(--yellow-rgb), 0.6);
    }
  }

  &:hover {
    background-color: rgba(var(--blue-rgb), 0.6);
    .story-actions {
      display: flex;
    }
  }

  .story-actions {
    display: none;
    flex-direction: column;
    align-items: flex-end;

    .button {
      margin: 0.125rem 0;
      font-size: 1rem;
    }
  }

  &__story-name {
    font-size: 1rem;
    word-break: break-all;
    a {
      font-size: inherit;
      background-color: inherit;
      color: inherit;
      border-bottom: 0.0625rem solid;

      &:hover {
        color: var(--background-color);
      }
    }
  }

  &--voted {
    background-color: rgba(var(--green-rgb), 0.4);
  }
  &--selected {
    background-color: rgba(var(--yellow-rgb), 0.4);
    .story-actions {
      display: flex;
    }
  }
  &--active {
    background-color: rgba(var(--blue-rgb), 0.4);
  }
  &--progress {
    background-color: rgba(var(--purple-rgb), 0.4);
    animation: 1s infinite bgcolorchange;
  }

  &:last-of-type {
    border-bottom: 0.0625rem solid var(--green);
  }

  &__active-status,
  &__voted-status,
  &__progress-status {
    font-size: 0.75rem;
    margin-right: 0.5rem;
  }
}

@keyframes bgcolorchange {
  0% {
    background-color: rgba(var(--purple-rgb), 0.4);
  }
  50% {
    background-color: rgba(var(--purple-rgb), 0.6);
  }
  100% {
    background-color: rgba(var(--purple-rgb), 0.4);
  }
}
/* Chrome, Safari, Opera */
@-webkit-keyframes bgcolorchange {
  0% {
    background-color: rgba(var(--purple-rgb), 0.4);
  }
  50% {
    background-color: rgba(var(--purple-rgb), 0.6);
  }
  100% {
    background-color: rgba(var(--purple-rgb), 0.4);
  }
}

@keyframes highlithModeSelector {
  0% {
    background-color: var(--background-color);
  }
  50% {
    background-color: var(--purple);
  }
  100% {
    background-color: var(--background-color);
  }
}

@keyframes highlithModeSelector2 {
  0% {
    background-color: var(--background-color);
  }
  50% {
    background-color: var(--fuschia);
  }
  100% {
    background-color: var(--background-color);
  }
}

@-webkit-keyframes highlithModeSelector {
  0% {
    background-color: var(--background-color);
  }
  50% {
    background-color: var(--purple);
  }
  100% {
    background-color: var(--background-color);
  }
}

@-webkit-keyframes highlithModeSelector2 {
  0% {
    background-color: var(--background-color);
  }
  50% {
    background-color: var(--fuschia);
  }
  100% {
    background-color: var(--background-color);
  }
}

@media screen and (min-width: $mobile-min-width) {
  .story-list {
    display: block;
    display: unset;

    &__stories {
      height: calc(100vh - 220px);
      overflow: scroll;
      overflow-x: hidden;
    }
    &__head {
      font-size: 2rem;
    }

    &__sorting-button {
      font-size: 1.25rem;
    }

    &__content {
      position: unset;
    }

    &__menu {
      display: none;
      z-index: 101;
    }
  }
}

.line1,
.line2,
.line3 {
  width: 35px;
  height: 5px;
  background-color: var(--text-color);
  margin: 6px 0;
  transition: 0.4s;
}

.story-list {
  &__stories {
    scrollbar-width: none;
  }
  &__stories::-webkit-scrollbar {
    display: none;
  }
}

.opened .line1 {
  -webkit-transform: rotate(-45deg) translate(-0.5625rem, 0.375rem);
  transform: rotate(-45deg) translate(-0.5625rem, 0.375rem);
}

.opened .line2 {
  opacity: 0;
}

.opened .line3 {
  -webkit-transform: rotate(45deg) translate(-0.5625rem, -0.4375rem);
  transform: rotate(45deg) translate(-0.5625rem, -0.4375rem);
}
