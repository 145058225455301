@import "../../../variables.scss";

.pagination {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  bottom: 4.6rem;

  &__direction {
    position: relative;
    cursor: pointer;
    font-size: 1.2rem;
  }

  &__direction::before {
    position: relative;
    content: "[";
  }

  &__direction::after {
    position: relative;
    content: "]";
  }

  &__list {
    display: flex;
    flex-direction: row;
    padding: 0;
  }

  &__numbers {
    list-style-type: none;
    margin: 0 0.6rem;
  }

  &__link {
    display: flex;
    font-size: 1.2rem;
    cursor: pointer;
    &--active {
      color: var(--blue);
    }
  }
}

@media screen and (min-width: $mobile-min-width) {
  .pagination {
    flex-direction: row;
    bottom: 2.5rem;
    &__direction {
      font-size: 1.7rem;
    }

    &__link {
      font-size: 1.7rem;
    }
  }
}
