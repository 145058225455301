.dashboard-page {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    font-size: 3.2rem;
    margin: 1.3rem auto;
  }
}

