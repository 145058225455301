@import "../../../variables.scss";

.home-page {
  &__content {
    width: 100%;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    flex-direction: column;

    .button {
      margin: 1rem;
      font-size: 2.5rem;
    }
  }
}

@media screen and (min-width: $tablet-min-width) {
  .home-page {
    &__content {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
}