@import "../../../variables.scss";

.connected-users {
  &__content {
    margin-top: 1.5rem;
    margin-left: 2.4rem;
  }

  &__userscount {
    display: block;
    margin-left: 8rem;
  }
}

@media screen and (min-width: $mobile-min-width) {
  .connected-users {
    &__content {
      display: flex;
      justify-content: center;
    }

    &__userscount {
      margin-left: 1.5rem;
    }
  }
}
