@import "../../../variables.scss";

.notifications {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 999;
  width: 100%;

  &__close {
    align-self: center;
  }

  &__item {
    min-width: 100%;
    border-radius: 1rem;
    margin: 1rem auto;
    padding: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &--error {
      background-color: var(--notification-error);
    }

    &--success {
      background-color: var(--notification-success);
    }

    &--warning {
      background-color: var(--notification-warning);
    }
  }
}

@media screen and (min-width: $mobile-min-width) {
  .notifications { 
    width: unset;
    &__item {
      min-width: $mobile-min-width;
    }
  }
}