.connected-users {
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  &__content {
    font-size: 2rem;
  }

  &__count {
    font-size: 2rem;
  }
}
