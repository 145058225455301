@import "../../../variables.scss";

.new-admin {
  &__add {
    display: flex;
    justify-content: flex-end;
  }

  .button {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: $tablet-min-width) {
  .new-admin {
    .button {
      font-size: 1.5rem;
    }
  }
}
