@import "../../../variables.scss";

.backups {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 100%;

  &__meta {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
  }

  &__name {
    text-align: center;
    font-size: 1.6rem;
  }

  &__actions {
    &--active {
      display: block;
    }

    padding: 1rem 0;
    width: 100%;
    display: none;
    text-align: center;

    .button {
      text-align: left;
      font-size: 1.5rem;
      margin: 0.5rem 0.5rem 0;

      &:nth-of-type(2n) {
        text-align: right;
      }
    }
  }

  &__content {
    padding: 1rem 0;
    width: 100%;
  }
}

@media screen and (min-width: $tablet-min-width) {
  .backups {
    display: flex;
    flex-direction: row;

    &__meta {
      flex-basis: 100%;
      justify-content: flex-start;
    }

    &__actions {
      display: flex;
      padding: 0;
      justify-content: flex-end;

      .button {
        flex-basis: auto;
        width: auto;
        margin: 0 0.4rem;
        align-self: baseline;
      }
    }
  }
}
